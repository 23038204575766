import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Checkbox, Divider, Empty, Tag } from 'antd';
import { debounce, filter, forEach, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import FilterSelectedIcon from '../../../assets/filter-selected.svg';
import FilterIcon from '../../../assets/filter.svg';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import {
  GET_LINE_OF_BUSINESS,
  GET_SUB_AREAS,
  SUB_AREA_FILTER
} from '../graphql/Queries';

let scrollDebounce = null;
const SubAreaTable = (props) => {
  const { match: { params: { lobId } = {} } = {} } = props;
  const {
    state: { pageSize, filterData },
    dispatch
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialSubAreaFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
    justShow: true,
    lobId
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  // const [showImportModal, setShowImportModal] = useState(false); // commented for now because of mvp.
  const [sortedInfo, setSortedInfo] = useState({});
  // const [selectedKeys, setSelectedKeys] = useState([]); // commented for now because of mvp.
  const [subAreaFilter, setSubAreaFilter] = useState(initialSubAreaFilter);
  const [filters, setFilters] = useState(null);
  const [filtersCopyState, setFiltersCopyState] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const { data: { lineOfBusiness } = {} } = useQuery(GET_LINE_OF_BUSINESS, {
    variables: { id: lobId },
    onCompleted: () => {},
    fetchPolicy: 'network-only',
    onError: () => {}
  });

  const [fetchSubAreaData, { loading, data }] = useLazyQuery(GET_SUB_AREAS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.subAreas?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const [subAreaFilters] = useLazyQuery(SUB_AREA_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        if (res?.subAreaFilters?.data?.length < SKIP_RECORD) {
          setFilterIsEnd(true);
        }
        setFilterList(optionsCopy);
      }
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  useEffect(() => {
    fetchSubAreaData({
      variables: {
        filter: subAreaFilter,
        ...(filterData && { where: filterData })
      }
    });
    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    fetchSubAreaData({
      variables: {
        filter: { ...subAreaFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setSubAreaFilter({
      ...subAreaFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        default:
          subAreaFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex,
                justShow: true,
                lobId
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  // commented for now because of mvp.
  // const handleShowImportModal = (visible) => {
  //   setShowImportModal(visible);
  // };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          default:
            subAreaFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex,
                  justShow: true,
                  lobId
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  // commented for now because of mvp.
  // const rowSelection = {
  //   fixed:  'left',
  //   selectedRowKeys: selectedKeys,
  //   onChange: (selectedRowKeys) => {
  //     setSelectedKeys(selectedRowKeys);
  //   }
  // };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    setFilters(filtersCopy);
    fetchSubAreaData({
      variables: {
        filter: {
          ...subAreaFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC'
        },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    clearFilters();
  };

  // commented for now because of mvp.
  // const handleAddEditSubArea = (record) => {
  //   if (record?.id) {
  //     dispatch({ type: 'SET_FILTER_DATA', data: filters });
  //     history?.push(
  //       `${ROUTES?.LINE_OF_BUSINESSES}/${lobId}${ROUTES?.SUB_AREAS}/edit/${record?.id}`
  //     );
  //   } else {
  //     history?.push(
  //       `${ROUTES?.LINE_OF_BUSINESSES}/${lobId}${ROUTES?.SUB_AREAS}/add`
  //     );
  //   }
  // };

  // const importCallback = () => {
  //   setPaginationProp(initialPaginationValue);
  //   setSubAreaFilter(initialSubAreaFilter);
  //   setSortedInfo({});
  //   fetchSubAreaData({
  //     variables: { filter: initialSubAreaFilter }
  //   });
  // };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setSubAreaFilter({
        ...subAreaFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchSubAreaData({
        variables: {
          filter: {
            ...subAreaFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setSubAreaFilter({
        ...subAreaFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchSubAreaData({
        variables: {
          filter: {
            ...subAreaFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      default:
        subAreaFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex,
              justShow: true,
              lobId
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(filtersCopyState[dataIndex], (item) => item !== value)
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'isActive' && (
            <SearchComponent
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => {
                return (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <span title={item?.label || item?.toString()}>
                        {item?.label || item?.toString()}
                      </span>
                    )}
                  </Tag>
                );
              })}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => {
                return (
                  <div className="single-checkbox-div" key={item?.key || item}>
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      {dataIndex === 'isActive' ? (
                        <span title={item === true ? 'Active' : 'Inactive'}>
                          {item === true ? 'Active' : 'Inactive'}
                        </span>
                      ) : (
                        <span title={item?.label || item?.toString()}>
                          {item?.label || item?.toString()}
                        </span>
                      )}
                    </Checkbox>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="reset-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button ok-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters && filters[dataIndex]?.length > 0 ? (
        <img src={FilterSelectedIcon} alt="filter-icon" width={16} />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownVisibleChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  // commented for now because of mvp.
  // const handleDeleteSubArea = async (editSubAreaData) => {
  //   const response = await deleteSubArea({
  //     variables: { where: { id: editSubAreaData?.id } }
  //   });
  //   if (response?.data?.deleteSubArea) {
  //     fetchSubAreaData({
  //       variables: { filter: subAreaFilter, ...(filters && { where: filters }) }
  //     });
  //   }
  // };

  // const handleSubAreaStatus = async (editSubAreaData) => {
  //   const editData = {
  //     isActive: !editSubAreaData.isActive
  //   };
  //   const response = await updateSubAreaMutate({
  //     variables: { data: { ...editData }, where: { id: editSubAreaData?.id } }
  //   });
  //   if (response?.data?.updateSubArea) {
  //     fetchSubAreaData({
  //       variables: { filter: subAreaFilter, ...(filters && { where: filters }) }
  //     });
  //   }
  // };

  // commented below code for now as we have only one Line of business and two sub areas
  // const renderActionButtons = (editSubAreaData) => {
  //   return (
  //     <div className="d-flex flex-vertical">
  //       {/* commented for now because of mvp */}
  //       {/* <Button
  //         id="subArea-table-edit-btn"
  //         className="b-0"
  //         onClick={() => handleAddEditSubArea(editSubAreaData)}
  //       >
  //         Edit
  //       </Button> */}
  //       {/* <Popconfirm
  //         title="Are you sure to delete?"
  //         onConfirm={() => handleDeleteSubArea(editSubAreaData)}
  //         okText="Yes"
  //         cancelText="No"
  //       >
  //         <Button id="subArea-table-status-btn" className="b-0">
  //           Remove
  //         </Button>
  //       </Popconfirm> */}
  //       <Popconfirm
  //         title={`Are you sure to ${
  //           editSubAreaData?.isActive ? 'Mark Inactive' : 'Mark Active'
  //         }?`}
  //         onConfirm={() => handleSubAreaStatus(editSubAreaData)}
  //         okText="Yes"
  //         cancelText="No"
  //       >
  //         <Button id="subArea-table-status-btn" className="b-0">
  //           {editSubAreaData?.isActive ? 'Mark Inactive' : 'Mark Active'}
  //         </Button>
  //       </Popconfirm>
  //     </div>
  //   );
  // };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'label',
      key: 'label',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'label' && sortedInfo?.order,
      width: 200
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      className: 'max-width-column',
      ellipsis: true,
      render: (description = '') => {
        const strippedString = description.replace(/(<([^>]+)>)/gi, '');
        return <span>{strippedString || '-'}</span>;
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      width: 120,
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    }
    // commented below code for now as we have only one Line of business and two sub areas
    // {
    //   dataIndex: 'id',
    //   align: 'right',
    //   width: 50,
    //   fixed: 'right',
    //   render: (id, record) => {
    //     return (
    //       <Popover
    //         placement="bottom"
    //         overlayClassName="action-button"
    //         content={renderActionButtons(record)}
    //       >
    //         <MoreOutlined />
    //       </Popover>
    //     );
    //   }
    // }
  ];

  const onSearchChange = (value) => {
    setSubAreaFilter({
      ...subAreaFilter,
      skip: value ? 0 : subAreaFilter?.limit * (paginationProp?.current - 1),
      search: value
    });
    fetchSubAreaData({
      variables: {
        filter: {
          ...subAreaFilter,
          skip: value
            ? 0
            : subAreaFilter?.limit * (paginationProp?.current - 1),
          search: value
        },
        ...(filters && { where: filters })
      }
    });
  };

  // commented for now because of mvp.
  // const handleExport = (e) => {
  //   const { key } = e;
  //   if (key === 'SELECTED' && !selectedKeys?.length) {
  //     message?.destroy();
  //     message?.error('Please select records!');
  //     return;
  //   }
  //   setExportLoading(true);
  //   api({
  //     method: 'POST',
  //     url: `${process?.env?.REACT_APP_SERVER_REST_URL}/export`,
  //     data: {
  //       module: 'SUBAREA',
  //       userId: currentUser?.id,
  //       filtersObj: {
  //         ...filters,
  //         id: key === 'SELECTED' ? selectedKeys : undefined
  //       }
  //     }
  //   })
  //     .then((res) => {
  //       handleCsvDownload(res?.data);
  //       setExportLoading(false);
  //     })
  //     .catch((error) => {
  //       message?.error(error?.response?.data?.error || 'got some problem');
  //       setExportLoading(false);
  //     });
  // };

  // commented for now because of mvp.
  // const exportContent = (
  //   <Menu onClick={handleExport}>
  //     <Menu.Item key="SELECTED" id="selected-export">
  //       <span>Selected</span>
  //     </Menu.Item>
  //     <Menu.Item key="ALL" id="all-export">
  //       <span>All</span>
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <div>
      {/* commented for now because of mvp */}
      {/* <ImportModal
      showImportModal={showImportModal}
      setShowImportModal={setShowImportModal}
      callback={importCallback}
      module="sub-area"
    /> */}
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={`${ROUTES?.LINE_OF_BUSINESSES}`} />
          <span className="portal-header">{lineOfBusiness?.label}</span>
        </div>
      </Portal>
      {/* commented for now because of mvp */}
      {/* <Portal portalId="header-right-content">
      <Button
        className="common-button"
        icon={<img src={SubAreaIcon} alt="import-icon" width={12} />}
        size="small"
        id="subArea-table-add-btn"
        type="primary"
        onClick={handleAddEditSubArea}
      >
        Add Sub Area
      </Button>
    </Portal> */}
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            // className="list-search-box"
            id="search-container-id"
            placeholder="Sub Area name or other detail.."
            name="SubAreas"
            getData={onSearchChange}
          />
        </div>
        {/* commented for now because of mvp */}
        {/* <div className="header-buttons">
        <Button
          size="small"
          className="common-button import-button"
          icon={<img src={ImportIcon} alt="import-icon" width={11} />}
          id="subArea-table-import-btn"
          type="primary"
          onClick={() => handleShowImportModal(true)}
        >
          Import
        </Button>
        <Dropdown
          overlayClassName="export-btn-dropdown"
          overlay={exportContent}
          placement="bottomCenter"
        >
          <Button
            className="common-button export-button"
            size="small"
            icon={<img src={ExportIcon} alt="import-icon" width={11} />}
            id="subArea-table-export-btn"
            loading={exportLoading}
            type="primary"
          >
            Export
          </Button>
        </Dropdown>
      </div> */}
      </div>
      <div className="common-table first-column-table">
        {pageSize && (
          <TableComponent
            loadingData={
              loading
              // || updateSubAreaLoading || deleteSubAreaLoading // commented for now because of mvp.
            }
            columns={columns}
            data={data?.subAreas?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            // rowSelection={rowSelection} // commented for now because of mvp.
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </div>
  );
};
export default withRouter(SubAreaTable);
