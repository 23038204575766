import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import documentSvg from '../../../assets/document.svg';
import infoSvg from '../../../assets/info.svg';
import { ROUTES } from '../../../common/constants';

const { TabPane } = Tabs;

const TenantTabs = ({ match: { params: { id } = {} } = {}, children }) => {
  const location = useLocation();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState('BRANDS');

  const infoTitle = (
    <div>
      <img src={infoSvg} alt="info-icon" />
      <span className="card-title">Information</span>
    </div>
  );

  const retailerTitle = (
    <div className="d-flex">
      <img src={infoSvg} alt="info-icon" />
      <span className="card-title">Retailers</span>
    </div>
  );

  const lobTitle = (
    <div className="d-flex">
      <img src={documentSvg} alt="info-icon" />
      <span className="card-title">Lines of Business</span>
    </div>
  );

  const userTitle = (
    <div className="d-flex">
      <img src={documentSvg} alt="info-icon" />
      <span className="card-title">Users</span>
    </div>
  );

  const planTitle = (
    <div className="d-flex">
      <img src={documentSvg} alt="info-icon" />
      <span className="card-title">Plan history</span>
    </div>
  );

  useEffect(() => {
    if (
      location?.pathname?.includes('edit') ||
      location?.pathname?.includes('view') ||
      location?.pathname?.includes('add')
    ) {
      setActiveKey('INFORMATION');
    }

    if (location?.pathname?.includes('retailers')) {
      setActiveKey('RETAILERS');
    }
    if (location?.pathname?.includes('line-of-business')) {
      setActiveKey('LINE_OF_BUSINESSES');
    }
    if (location?.pathname?.includes('users')) {
      setActiveKey('USERS');
    }
    if (location?.pathname?.includes('plans')) {
      setActiveKey('PLANS');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectTab = (key) => {
    setActiveKey(key);
    switch (key) {
      case 'INFORMATION':
        if (id) {
          // history?.push(`${ROUTES?.TENANT}/view/${id}`);
          if (location?.pathname?.includes('edit')) {
            history?.push(`${ROUTES?.TENANT}/edit/${id}`);
          } else {
            history?.push(`${ROUTES?.TENANT}/view/${id}`);
          }
        } else {
          history?.push(`${ROUTES?.TENANT}/add`);
        }
        break;

      case 'RETAILERS':
        history?.push(`${ROUTES?.TENANT}/view/${id}${ROUTES?.BRANDS}`);
        break;

      case 'LINE_OF_BUSINESSES':
        history?.push(
          `${ROUTES?.TENANT}/view/${id}${ROUTES?.LINE_OF_BUSINESSES}`
        );
        break;

      case 'USERS':
        history?.push(`${ROUTES?.TENANT}/view/${id}${ROUTES?.USERS}`);
        break;

      case 'PLANS':
        history?.push(`${ROUTES?.TENANT}/view/${id}${ROUTES?.PLANS}`);
        break;

      default:
        break;
    }
  };

  return (
    <div className="card-container">
      <Tabs type="card" activeKey={activeKey} onChange={handleSelectTab}>
        <TabPane tab={infoTitle} key="INFORMATION">
          {children}
        </TabPane>
        {!location?.pathname?.includes('add') &&
          !location?.pathname?.includes('edit') && (
            <TabPane tab={retailerTitle} key="RETAILERS">
              {children}
            </TabPane>
          )}
        {!location?.pathname?.includes('add') &&
          !location?.pathname?.includes('edit') && (
            <TabPane tab={lobTitle} key="LINE_OF_BUSINESSES">
              {children}
            </TabPane>
          )}
        {!location?.pathname?.includes('add') &&
          !location?.pathname?.includes('edit') && (
            <TabPane tab={userTitle} key="USERS">
              {children}
            </TabPane>
          )}
        {!location?.pathname?.includes('add') &&
          !location?.pathname?.includes('edit') && (
            <TabPane tab={planTitle} key="PLANS">
              {children}
            </TabPane>
          )}
      </Tabs>
    </div>
  );
};

export default withRouter(TenantTabs);
