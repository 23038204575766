import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import TenantDetails from '../components/TenantDetails';
import CardWrapper from '../components/TenantTabs';
import { FETCH_TENANT } from '../graphql/Queries';

export const TenantView = (props) => {
  const { match: { params: { id } = {} } = {} } = props;
  const [loading, setLoading] = useState(true);

  const { data } = useQuery(FETCH_TENANT, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  if (!id) {
    <Redirect to={ROUTES?.TENANT} />;
  }

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={`${ROUTES?.TENANT}`} />
          <span className="portal-header">{data?.tenant?.name}</span>
        </div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          type="primary"
          onClick={() => history?.push(`${ROUTES?.TENANT}/edit/${id}`)}
        >
          Edit
        </Button>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <TenantDetails tenantData={data?.tenant} />
        )}
      </CardWrapper>
    </Card>
  );
};

export default withRouter(TenantView);
