import { Col, Divider, Row } from 'antd';
import React from 'react';
import { formatPhoneNumber } from '../../../common/utils';

const TenantDetails = ({ tenantData }) => {
  return (
    <div className="tenant-details">
      <span className="form-divider-text">MANDATORY</span>
      <Divider className="form-divider" />
      <Row gutter={20}>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">First Name :</span>
            <span className="field-value">
              {tenantData?.userInfo?.firstName || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Last Name :</span>
            <span className="field-value">
              {tenantData?.userInfo?.lastName || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">PhoneNo :</span>
            <span className="field-value">
              {formatPhoneNumber(tenantData?.phoneNo) || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Email :</span>
            <span className="field-value">
              {tenantData?.userInfo?.email || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Business Name :</span>
            <span className="field-value">{tenantData?.name || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Tenant Email :</span>
            <span className="field-value">{tenantData?.email || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Sub Domain :</span>
            <span className="field-value">{tenantData?.subDomain || '-'}</span>
          </div>
        </Col>
        <Col>
          <div className="field-detail">
            <span className="field-label">Status :</span>
            <span className="field-value">
              {tenantData?.isActive ? 'Active' : 'InActive'}
            </span>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <span className="form-divider-text optional-divider">OPTIONAL</span>
        <Divider className="form-divider optional-divider" />
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">AddressLine 1 :</span>
            <span className="field-value">
              {tenantData?.location?.addressLine1 || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Country :</span>
            <span className="field-value">
              {tenantData?.location?.country || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">State :</span>
            <span className="field-value">
              {tenantData?.location?.state || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">City :</span>
            <span className="field-value">
              {tenantData?.location?.city || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">ZipCode :</span>
            <span className="field-value">
              {tenantData?.location?.zipCode || '-'}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default TenantDetails;
