import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation adminCreateUser($data: AdminUserInput!) {
    adminCreateUser(data: $data) {
      data {
        id
        firstName
        lastName
        email
        phoneNo
        isActive
        roles
      }
      status
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation adminUpdateUser($data: AdminUserInput!, $where: UserWhereInput!) {
    adminUpdateUser(data: $data, where: $where) {
      data {
        id
        firstName
        lastName
        email
        phoneNo
        isActive
        roles
      }
      status
      message
    }
  }
`;

export const DELETE_USER = gql`
  mutation adminDeleteUser($where: UserWhereInput!) {
    adminDeleteUser(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
export const CHANGE_PASSWORD = gql`
  mutation changePassword($data: changePasswordInput!) {
    changePassword(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($data: UserInput!) {
    updateProfile(data: $data) {
      message
      status
      data {
        id
        firstName
        lastName
        email
        phoneNo
        roles

        isActive
        tenantId
      }
    }
  }
`;
