import {
  getElementFromDocumentId,
  isDocumentIdExist
} from '../../common/utils';
import history from '../../historyData';

const redirectUser = (path) => {
  history?.push(path);
};

const keysDown = (e) => {
  if (e.ctrlKey && e.shiftKey) {
    switch (e.which) {
      case 70:
        // ctrl + shift + f
        if (isDocumentIdExist('search-container-id')) {
          const searchId = getElementFromDocumentId('search-container-id');
          // eslint-disable-next-line no-undef
          if (document.activeElement === searchId) {
            searchId.blur();
          } else {
            searchId.focus();
          }
        }
        break;

      case 83:
        // ctrl + shift + s
        if (isDocumentIdExist('btn-submit-id')) {
          const submitBtn = getElementFromDocumentId('btn-submit-id');
          submitBtn.click();
        }
        break;

      case 191:
        // ctrl + shift + /
        if (isDocumentIdExist('info-div-id')) {
          const infoModalId = getElementFromDocumentId('info-div-id');
          infoModalId.click();
        }
        break;
      default:
        break;
    }
  } else if (e.ctrlKey && e.altKey) {
    switch (e.which) {
      case 69:
        // ctrl + alt + e
        if (isDocumentIdExist('export-doc-id')) {
          const exportId = getElementFromDocumentId('export-doc-id');
          exportId.click();
        }
        break;

      case 73:
        // ctrl + alt + i
        if (isDocumentIdExist('btn-import-id')) {
          const importBtn = getElementFromDocumentId('btn-import-id');
          importBtn.click();
        }
        break;

      case 76:
        // ctrl + alt + l
        if (isDocumentIdExist('export-all-id')) {
          const exportAll = getElementFromDocumentId('export-all-id');
          exportAll.click();
        }
        break;

      case 85:
        // ctrl + alt + u
        redirectUser('/users');
        break;

      case 72:
        // ctrl + alt + h
        redirectUser('/');
        break;

      default:
        break;
    }
  }
};

export default keysDown;
