import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import BrandList from './pages/BrandList';
import LineOfBusinessList from './pages/LineOfBusinessList';
import PlanList from './pages/PlanList';
import SubAreaList from './pages/SubAreaList';
import AddEditTenant from './pages/TenantCreate';
import TenantEdit from './pages/TenantEdit';
import TenantList from './pages/TenantList';
import { TenantView } from './pages/TenantView';
import UsersList from './pages/UsersList';
import './tenant.less';

const TenantWrapper = () => {
  return (
    <div className="tenant-module">
      <Switch>
        <Route exact path={ROUTES?.TENANT} component={TenantList} />
        <Route path={`${ROUTES?.TENANT}/add`} component={AddEditTenant} />
        <Route
          exact
          path={`${ROUTES?.TENANT}/edit/:id`}
          component={TenantEdit}
        />
        <Route
          exact
          path={`${ROUTES?.TENANT}/view/:id`}
          component={TenantView}
        />
        <Route
          exact
          path={`${ROUTES?.TENANT}/view/:id${ROUTES?.BRANDS}`}
          component={BrandList}
        />
        <Route
          exact
          path={`${ROUTES?.TENANT}/view/:id${ROUTES?.LINE_OF_BUSINESSES}`}
          component={LineOfBusinessList}
        />
        <Route
          exact
          path={`${ROUTES?.TENANT}/view/:id${ROUTES?.USERS}`}
          component={UsersList}
        />
        <Route
          exact
          path={`${ROUTES?.TENANT}/view/:id${ROUTES?.LINE_OF_BUSINESSES}/:lobId${ROUTES?.SUB_AREAS}`}
          component={SubAreaList}
        />
        <Route
          exact
          path={`${ROUTES?.TENANT}/view/:id${ROUTES?.PLANS}`}
          component={PlanList}
        />
      </Switch>
    </div>
  );
};
export default TenantWrapper;
