import { Card } from 'antd';
import React, { useState } from 'react';
import Portal from '../../../components/Portal';
import AddPermissionModal from '../components/AddPermissionModal';
import PermissionTable from '../components/PermissionTable';

const PermissionList = () => {
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState();
  return (
    <Card>
      {showModal && (
        <AddPermissionModal
          showModal={showModal}
          setShowModal={setShowModal}
          setUserData={setUserData}
          userData={userData}
          isUpdate={!!userData}
        />
      )}
      <Portal portalId="header-left-content">
        <h1>Permissions</h1>
      </Portal>
      <PermissionTable />
    </Card>
  );
};

export default PermissionList;
