import { gql } from '@apollo/client';

export const FETCH_PLANS = gql`
  query subscriptionPlans($filter: SubscriptionPlansFilter!) {
    subscriptionPlans(filter: $filter) {
      count
      data {
        id
        name
        description
        features
        price
        isActive
        typeOfPlan
        billingCycle
        createdAt
        updatedAt
      }
    }
  }
`;

export const FETCH_PLAN = gql`
  query subscriptionPlan($where: SubscriptionWhereInput!) {
    subscriptionPlan(where: $where) {
      id
      name
      description
      features
      price
      isActive
      createdAt
      updatedAt
      typeOfPlan
      billingCycle
    }
  }
`;
