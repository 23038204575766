import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import PlanEdit from './pages/PlanEdit';
import PlansList from './pages/PlanList';
import './plansModule.less';

const plansWrapper = () => {
  return (
    <div className="plan-module">
      <Switch>
        <Route exact path={ROUTES.PLANS} component={PlansList} />
        <Route path={`${ROUTES?.PLANS}/edit/:id`} component={PlanEdit} />
      </Switch>
    </div>
  );
};

export default plansWrapper;
