import Modal from 'antd/lib/modal/Modal';
import React from 'react';

const AddRoleModal = (props) => {
  const { isUpdate, showModal, setShowModal, setUserData } = props;

  const handleCancel = () => {
    setShowModal(false);
    setUserData();
  };

  const handleAdd = () => {
    setShowModal(true);
  };

  return (
    <Modal
      title={isUpdate ? 'Edit role' : 'Add role'}
      visible={showModal}
      confirmLoading={false} // Add loading state here
      onOk={handleAdd}
      className="dialog"
      okText={isUpdate ? 'Save' : 'Add'}
      onCancel={handleCancel}
    >
      <h1>Role Modal</h1>
    </Modal>
  );
};

export default AddRoleModal;
