import { useLazyQuery } from '@apollo/client';
import { Button, Checkbox, Divider, Empty, Tag } from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import FilterSelectedIcon from '../../../assets/filter-selected.svg';
import FilterIcon from '../../../assets/filter.svg';
import placeholderImage from '../../../assets/placeholder-image.png';
import { SKIP_RECORD } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import RangePickerComponent from '../../../components/RangePickerComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import { BRAND_FILTER, FETCH_TENANT_BRANDS } from '../graphql/Queries';

let scrollDebounce = null;

const initialPaginationValue = {
  total: 0,
  current: 1
};

const BrandTable = ({ tenantId }) => {
  const {
    state: { pageSize, filterData },
    dispatch
  } = useContext(AppContext);

  const initialBrandFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
    tenantId
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [brandFilter, setBrandFilter] = useState(initialBrandFilter);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [filters, setFilters] = useState(null);
  const [filtersCopyState, setFiltersCopyState] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [brandFilters] = useLazyQuery(BRAND_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.brandFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.brandFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
      }
      if (res?.brandFilters?.data?.length < SKIP_RECORD) {
        setFilterIsEnd(true);
      }
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [fetchTenantBrand, { loading, data }] = useLazyQuery(
    FETCH_TENANT_BRANDS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.brands?.count
        };
        setPaginationProp(pagination);
        setFetchLoading(false);
      },
      onError() {}
    }
  );

  useEffect(() => {
    fetchTenantBrand({
      variables: {
        filter: brandFilter,
        ...(filterData && { where: filterData })
      }
    });
    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    fetchTenantBrand({
      variables: {
        filter: { ...brandFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setBrandFilter({
      ...brandFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'createdAt':
          // no api call for range filter;
          break;

        default:
          brandFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex,
                tenantId
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          default:
            brandFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex,
                  tenantId
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleReset = (clearFilters, dataIndex) => {
    let filtersCopy = {
      ...filters
    };
    if (dataIndex === 'createdAt') {
      delete filtersCopy?.createdAt;
    } else {
      filtersCopy = {
        ...filters,
        [dataIndex]: []
      };
    }
    setFilters(filtersCopy);
    fetchTenantBrand({
      variables: {
        filter: {
          ...brandFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC'
        },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    setBrandFilter({
      ...brandFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setBrandFilter({
        ...brandFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchTenantBrand({
        variables: {
          filter: {
            ...brandFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setBrandFilter({
        ...brandFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchTenantBrand({
        variables: {
          filter: {
            ...brandFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };
  const onSearchChange = async (value) => {
    fetchTenantBrand({
      variables: {
        filter: {
          ...brandFilter,
          skip: value ? 0 : brandFilter?.limit * (paginationProp?.current - 1),
          search: value
        }
      }
    });
    setFetchLoading(true);
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (filterIndex) {
      default:
        brandFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex,
              tenantId
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(filtersCopyState[dataIndex], (item) => item !== value)
    };
    setFiltersCopyState(filtersCopy);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: moment(values?.[0])?.startOf('day'),
      to: moment(values?.[1])?.endOf('day')
    };

    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: rangeObj
    };
    if (!values?.length) {
      delete filtersCopy?.[dataIndex];
    }
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown range-filter-dropdown">
            <RangePickerComponent
              format="MM/DD/yyyy"
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                filtersCopyState?.[dataIndex]?.from
                  ? [
                      moment(filtersCopyState?.[dataIndex]?.from, 'DD/MM/YYYY'),
                      moment(filtersCopyState?.[dataIndex]?.to, 'DD/MM/YYYY')
                    ]
                  : []
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="date-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {dataIndex !== 'isActive' && dataIndex !== 'createdAt' && (
              <SearchComponent
                // commented below line for future use
                // className="list-search-box filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                getData={(value) => handleSearch(value, dataIndex)}
              />
            )}
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item) => {
                  return (
                    <Tag
                      key={item?.toString()}
                      closable
                      onClose={() => handleDeleteFilter(item, dataIndex)}
                      className="filter-tag"
                    >
                      {dataIndex === 'createdAt' ? (
                        <span title={item?.label || item?.toString()}>
                          {moment(item?.label || item?.toString())?.format('L')}
                        </span>
                      ) : (
                        <>
                          {dataIndex === 'isActive' ? (
                            <span title={item === true ? 'Active' : 'Inactive'}>
                              {item === true ? 'Active' : 'Inactive'}
                            </span>
                          ) : (
                            <span title={item?.label || item?.toString()}>
                              {item?.label || item?.toString()}
                            </span>
                          )}
                        </>
                      )}
                    </Tag>
                  );
                })}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => {
                  return (
                    <div
                      className="single-checkbox-div"
                      key={item?.key || item}
                    >
                      <Checkbox
                        value={item?.key || item}
                        checked={filtersCopyState?.[dataIndex]?.includes(
                          item?.key || item
                        )}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        {dataIndex === 'createdAt' ? (
                          <span title={item?.label || item?.toString()}>
                            {moment(item?.label || item?.toString())?.format(
                              'L'
                            )}
                          </span>
                        ) : (
                          <>
                            {dataIndex === 'isActive' ? (
                              <span
                                title={item === true ? 'Active' : 'Inactive'}
                              >
                                {item === true ? 'Active' : 'Inactive'}
                              </span>
                            ) : (
                              <span title={item?.label || item?.toString()}>
                                {item?.label || item?.toString()}
                              </span>
                            )}
                          </>
                        )}
                      </Checkbox>
                    </div>
                  );
                })
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <img src={FilterSelectedIcon} alt="filter-icon" width={16} />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownVisibleChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      ...filterPopup('name'),
      render: (name, record) => {
        return (
          <>
            <img
              src={record?.logo?.url || placeholderImage}
              alt="brand-img"
              onError={(e) => {
                e.target.src = placeholderImage;
              }}
              className="list-img image-contain"
              width={24}
              height={24}
            />
            <span title={name}>{name}</span>
          </>
        );
      }
    },
    {
      title: 'ADDED ON',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      width: 140,
      ...filterPopup('createdAt'),
      render: (createdAt) => {
        return <span>{moment(createdAt)?.format('MM/DD/yyyy')}</span>;
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      width: 120,
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    }
  ];

  return (
    <div>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            // commented below line for future uses
            // size="small"
            // className="panel-search-box"
            id="search-container-id"
            placeholder="Retailer name or other detail.."
            name="Brand"
            getData={onSearchChange}
          />
        </div>
      </div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            loadingData={loading || fetchLoading}
            columns={columns}
            data={data?.brands?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </div>
  );
};
export default BrandTable;
