import { useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import LineOfBusinessTable from '../components/LineOfBusinessTable';
import CardWrapper from '../components/TenantTabs';
import { FETCH_TENANT } from '../graphql/Queries';

const LineOfBusinessList = (props) => {
  const { match: { params: { id } = {} } = {} } = props;
  const [loading, setLoading] = useState(true);

  const { data } = useQuery(FETCH_TENANT, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  if (!id) {
    <Redirect to={ROUTES?.TENANT} />;
  }
  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={`${ROUTES?.TENANT}`} />
          <span className="portal-header">{data?.tenant?.name}</span>
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <LineOfBusinessTable tenantId={id} />
        )}
      </CardWrapper>
    </Card>
  );
};
export default withRouter(LineOfBusinessList);
