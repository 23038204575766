import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import TableComponent from '../../../components/TableComponent';
import { FETCH_TENANT_SUB_AREA } from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1
};
const SubAreaTable = (props) => {
  const { match: { params: { lobId } = {} } = {} } = props;
  const {
    state: { pageSize, filterData },
    dispatch
  } = useContext(AppContext);

  const initialSubAreaFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
    justShow: true,
    lobId
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [subAreaFilter, setSubAreaFilter] = useState(initialSubAreaFilter);

  const [fetchSubAreas, { loading, data }] = useLazyQuery(
    FETCH_TENANT_SUB_AREA,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    fetchSubAreas({
      variables: {
        filter: subAreaFilter,
        ...(filterData && { where: filterData })
      }
    });
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'label',
      key: 'label',
      ellipsis: true,
      width: 200
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      className: 'max-width-column',
      ellipsis: true,
      render: (description = '') => {
        const strippedString = description?.replace(/(<([^>]+)>)/gi, '');
        return <span>{strippedString || '-'}</span>;
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      ellipsis: true,
      width: 120,
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    }
  ];

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pageSize;
    setSubAreaFilter({
      ...subAreaFilter,
      skip,
      limit: pagination?.pageSize
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    fetchSubAreas({
      variables: {
        filter: {
          ...subAreaFilter,
          lobId,
          skip,
          limit: pagination?.pageSize
        }
      }
    });
  };

  return (
    <div className="common-table">
      {pageSize && (
        <TableComponent
          loadingData={loading}
          columns={columns}
          data={data?.subAreas?.data || []}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(obj) => obj?.id}
        />
      )}
    </div>
  );
};
export default withRouter(SubAreaTable);
