import React from 'react';

const RoleTable = () => {
  return (
    <div>
      <h4>Role Table</h4>
    </div>
  );
};

export default RoleTable;
