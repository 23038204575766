import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import Error404 from '../Error404';
import ApiLogWrapper from '../modules/apiLogs';
import LineOfBusinessWrapper from '../modules/lineOfBusinesses';
import NotificationWrapper from '../modules/notification';
import PermissionWrapper from '../modules/permission';
import plansWrapper from '../modules/plans';
import RoleWrapper from '../modules/roles';
import TenantWrapper from '../modules/tenant';
import UserWrapper from '../modules/users';
import UserProfile from '../modules/users/pages/UserProfile';

const ContentRoutes = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={ROUTES?.MAIN}
          render={() => <Redirect to={ROUTES?.USERS} />}
        />
        <Route path={ROUTES?.USERS} component={UserWrapper} />
        <Route path={ROUTES?.PROFILE} component={UserProfile} />
        <Route path={ROUTES?.USERS_ROLES} component={RoleWrapper} />
        <Route path={ROUTES?.USERS_PERMISSION} component={PermissionWrapper} />
        <Route path={ROUTES?.NOTIFICATION} component={NotificationWrapper} />
        <Route path={ROUTES?.TENANT} component={TenantWrapper} />
        <Route
          path={ROUTES?.LINE_OF_BUSINESSES}
          component={LineOfBusinessWrapper}
        />
        <Route path={ROUTES?.PLANS} component={plansWrapper} />
        <Route path={ROUTES?.API_LOGS} component={ApiLogWrapper} />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
