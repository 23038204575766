import { gql } from '@apollo/client';

export const GET_SIGN_URL = gql`
  mutation generateSignedUrl(
    $action: String!
    $extension: String!
    $contentType: String
    $key: String!
  ) {
    generateSignedUrl(
      action: $action
      data: { extension: $extension, key: $key, contentType: $contentType }
    ) {
      signedRequest
      url
    }
  }
`;

export const CREATE_FILE = gql`
  mutation createImportFile($data: CreateImportFileInput!) {
    createImportFile(data: $data) {
      message
      status
      data {
        id
        importType
        importedById
        fileObj {
          url
          key
          name
          extension
          contentType
        }
        errorFileObj {
          url
          key
          name
          extension
          contentType
        }
        importFileStatus
        tenantId
        user {
          id
          firstName
          lastName
          email
          phoneNo
          isActive
          roles
          tenantId
          emailVerified
          locations {
            id
            isActive
            addressLine1
            addressLine2
            addressLine3
            city
            county
            state
            country
            zipCode
            tenantId
            createdAt
            updatedAt
          }
        }
        importFileType {
          id
          label
          webConfig
          sampleFile {
            url
            key
            name
            extension
            contentType
          }
          isActive
          key
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_ATTACHMENT = gql`
  mutation createAttachment($data: AttachmentInput!) {
    createAttachment(data: $data) {
      message
      status
      data {
        id
        referenceId
        key
        label
        type
        createdBy
        isActive
        url
        tags
        referenceKey
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation deleteAttachment($where: AttachmentWhereInput!) {
    deleteAttachment(where: $where) {
      message
      status
    }
  }
`;
