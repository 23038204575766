import { Button, Card } from 'antd';
import React, { useState } from 'react';
import Portal from '../../../components/Portal';
import AddRoleModal from '../components/AddRoleModal';
import RoleTable from '../components/RoleTable';

const RoleList = () => {
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState();
  const handleAdd = () => {
    setShowModal(true);
  };
  return (
    <Card>
      {showModal && (
        <AddRoleModal
          showModal={showModal}
          setShowModal={setShowModal}
          setUserData={setUserData}
          userData={userData}
          isUpdate={!!userData}
        />
      )}
      <Portal portalId="header-left-content">
        <h1>Roles</h1>
      </Portal>
      <Portal portalId="header-right-content">
        <Button type="primary" onClick={handleAdd}>
          Add Role
        </Button>
      </Portal>
      <RoleTable />
    </Card>
  );
};

export default RoleList;
