import { gql } from '@apollo/client';

export const GET_ADMIN_USERS = gql`
  query adminUsers($filter: UserFilter!, $where: UsersWhereFilter) {
    adminUsers(filter: $filter, where: $where) {
      count
      data {
        id
        firstName
        lastName
        email
        phoneNo
        roles
        roleValues {
          id
          key
          label
        }
        isActive
      }
    }
  }
`;

export const GET_USER = gql`
  query user($id: ID!) {
    user(where: { id: $id }) {
      id
      firstName
      lastName
      email
      phoneNo
      isActive
      roles
      roleValues {
        id
        key
        label
      }
      emailVerified
      locations {
        id
        isActive
        addressLine1
        addressLine2
        addressLine3
        city
        county
        state
        country
        zipCode
        createdAt
        updatedAt
      }
    }
  }
`;

export const USER_FILTER = gql`
  query userFilters($filter: UserFilter!) {
    userFilters(filter: $filter) {
      count
      data {
        firstName
        lastName
        email
        phoneNo
        isActive
        roles

        createdAt
        updatedAt
        emailVerified
        locations {
          id
          isActive
          addressLine1
          addressLine2
          addressLine3
          city
          county
          state
          country
          zipCode
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const ROLE_FILTER = gql`
  query roleFilters($filter: RoleFilter!) {
    roleFilters(filter: $filter) {
      count
      data {
        label
        description
        order
        isActive
        key
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query getProfile {
    getProfile {
      id
      firstName
      lastName
      email
      phoneNo
      roles
      profileImage {
        url
        key
        name
        extension
        contentType
      }
      isActive
    }
  }
`;

export const FETCH_STATE_CITY = gql`
  query fetchStatesAndCity($filter: USLocationTypeFilter) {
    getLocationType(filter: $filter) {
      data {
        id
        name
        state
      }
    }
  }
`;

export const ROLES = gql`
  query roles($filter: RoleFilter!, $where: RolesWhereFilter) {
    roles(filter: $filter, where: $where) {
      count
      data {
        id
        label
        description
        order
        isActive
        key

        createdAt
        updatedAt
      }
    }
  }
`;
