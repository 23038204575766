import { Input } from 'antd';
import React from 'react';
import './styles/NumberComponent.less';

const NumberComponent = (props) => {
  const { name } = props;

  return (
    <Input
      type="number"
      allowClear
      placeholder={`Enter ${name || ''}`}
      min={0}
      {...props}
    />
  );
};

export default NumberComponent;
