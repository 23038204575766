import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './lineofBusinessModule.less';
import LineOfBusinessList from './pages/LineOfBusinessList';
import SubAreaList from './pages/SubAreaList';

const LineOfBusinessWrapper = () => {
  // permission code not needed now.

  // const { getCurrentUserRole } = useContext(AppContext);
  // const userRoles = getCurrentUserRole();
  // const { TENANT_ADMIN } = ROLE_KEYS;
  // const isTenantAdmin = userRoles?.includes(TENANT_ADMIN);

  return (
    <div className="line-of-business-module">
      <Switch>
        {/* {isTenantAdmin && ( */}
        <>
          <Route
            exact
            path={ROUTES?.LINE_OF_BUSINESSES}
            component={LineOfBusinessList}
          />
          {/** commented below routes for now */}

          {/* <Route
            path={`${ROUTES?.LINE_OF_BUSINESSES}/add`}
            component={LineOfBusinessCreate}
          /> */}
          {/* <Route
            path={`${ROUTES?.LINE_OF_BUSINESSES}/edit/:lobId`}
            component={LineOfBusinessEdit}
          /> */}
          <Route
            exact
            path={`${ROUTES?.LINE_OF_BUSINESSES}/:lobId${ROUTES?.SUB_AREAS}`}
            component={SubAreaList}
          />
          {/* <Route
            path={`${ROUTES?.LINE_OF_BUSINESSES}/:lobId${ROUTES?.SUB_AREAS}/add`}
            component={SubAreaCreate}
          /> */}
          {/* <Route
            path={`${ROUTES?.LINE_OF_BUSINESSES}/:lobId${ROUTES?.SUB_AREAS}/edit/:subId`}
            component={SubAreaEdit}
          /> */}
        </>
        {/* )} */}
      </Switch>
    </div>
  );
};

export default LineOfBusinessWrapper;
