import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Spin } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import logo from '../../assets/logo-with-text.png';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { LOGIN } from './graphql/Mutations';

const { requiredWhiteSpaceAllowed, required, email } = formValidatorRules;

const Login = () => {
  const { initializeAuth } = useContext(AppContext);
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {}
  });

  function successCallback(accessToken, userData) {
    initializeAuth(accessToken, userData);
  }

  const onFinish = async (values) => {
    try {
      const response = await loginMutate({
        variables: { data: { ...values } }
      });

      const accessToken = response?.data?.adminLogin?.authToken;
      const userData = response?.data?.adminLogin?.user;
      if (accessToken && successCallback) {
        successCallback(accessToken, userData);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error from login => ', error);
    }
  };

  return (
    <>
      <div className="gx-login-container">
        <Spin spinning={loginLoading} wrapperClassName="gx-login-content">
          <div className="gx-login-header gx-text-center mb-0 fill-width">
            <img
              src={logo}
              width={287}
              alt="Pocket Testament League"
              className="mb-4"
            />
          </div>
          <Form
            name="Login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
          >
            <Form.Item name="email" rules={[requiredWhiteSpaceAllowed, email]}>
              <Input
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Email"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[required]}
              normalize={(value) => value?.split(' ')?.join('')}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item className="gx-text-center">
              <Button type="primary" className="fill-width" htmlType="submit">
                Login
              </Button>
            </Form.Item>
            <Form.Item className="d-flex mb-0 reset-password">
              <Link to={ROUTES?.RESET}>Forgot password ?</Link>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </>
  );
};

export default Login;
