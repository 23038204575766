import { Card } from 'antd';
import React from 'react';
import Portal from '../../../components/Portal';
import UserTable from '../components/UserTable';

const UserList = () => {
  return (
    <Card className="full-height-card card-body-p-20">
      <Portal portalId="header-left-content">
        <span className="portal-header">Users</span>
      </Portal>

      <UserTable />
    </Card>
  );
};

export default UserList;
