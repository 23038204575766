import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { RESET_PASSWORD, TOKEN_VALIDATION } from './graphql/Mutations';

const { required } = formValidatorRules;

const ChangePassword = (props) => {
  const [showForm, setShowForm] = useState(false);
  const { history, history: { location: { search } = {} } = {} } = props;

  const [tokenValidationMutate] = useMutation(TOKEN_VALIDATION, {
    onCompleted() {
      setShowForm(false);
    },
    onError() {
      setShowForm(false);
      history?.push(ROUTES?.LOGIN);
    }
  });

  const [resetPasswordMutate, { loading: resetPasswordLoading }] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted() {
        history?.replace('/login');
      },
      onError(err) {
        // eslint-disable-next-line no-console
        console.error('error from reset-password => ', err);
      }
    }
  );

  const getQuery = () => {
    return new URLSearchParams(search);
  };

  const token = getQuery()?.get('auth_token');
  const id = getQuery()?.get('uid');

  useEffect(() => {
    setShowForm(true);
    tokenValidationMutate({
      variables: { data: { id, token } }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = ({ password }) => {
    resetPasswordMutate({
      variables: { data: { id, token, password } }
    });
  };

  if (showForm) {
    return (
      <div className="gx-login-container">
        <Spin spinning />
      </div>
    );
  }

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-mb-4">
          <h2>Change Password</h2>
          <p>Enter a new password for your account</p>
        </div>
        <Spin spinning={false}>
          <Form
            name="change-password"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
          >
            <Form.Item
              name="password"
              rules={[required]}
              normalize={(value) => value?.split(' ')?.join('')}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              name="retype-password"
              normalize={(value) => value?.split(' ')?.join('')}
              rules={[
                required,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value !== getFieldValue('password')) {
                      return Promise?.reject(
                        new Error('Passwords do not match')
                      );
                    }
                    return Promise?.resolve();
                  }
                })
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Retype password"
              />
            </Form.Item>
            <div className="d-flex">
              <Space>
                <Form.Item>
                  <Button
                    loading={resetPasswordLoading}
                    type="primary"
                    className="mr-2"
                    htmlType="submit"
                  >
                    Change Password
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={() => {
                      history?.push(ROUTES?.LOGIN);
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ChangePassword;
