import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formatPhoneNumberWithoutMask } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import AddTenantForm from '../components/AddTenantForm';
import CardWrapper from '../components/TenantTabs';
import { EDIT_TENANT } from '../graphql/Mutations';
import { FETCH_TENANT } from '../graphql/Queries';

const TenantEdit = (props) => {
  const { match: { params: { id } = {} } = {} } = props;
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { tenant } = {} } = useQuery(FETCH_TENANT, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  const [updateTenant] = useMutation(EDIT_TENANT, {
    onCompleted() {
      history?.push(ROUTES?.TENANT);
    },
    onError() {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const {
      firstName,
      lastName,
      email: mail,
      tenantEmail,
      phoneNo,
      name,
      subDomain: domainName,
      description,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      county,
      country,
      state,
      zipCode,
      isActive
    } = formValues;

    const tenantInput = {
      name,
      subDomain: domainName,
      description,
      email: tenantEmail,
      phoneNo: formatPhoneNumberWithoutMask(phoneNo)?.slice(-10),
      isActive
    };
    const locationInput = {
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      county,
      country,
      state,
      zipCode
    };
    const userInput = {
      firstName,
      lastName,
      email: mail
    };

    try {
      const response = await updateTenant({
        variables: {
          data: {
            userInput,
            tenantInput,
            locationInput
          },
          where: {
            id: tenant?.id
          }
        }
      });
      if (response?.data?.createTenant) {
        history?.push(`${ROUTES?.TENANT}`);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  if (!id) {
    <Redirect to={ROUTES?.TENANT} />;
  }

  const initialValues = {
    name: tenant?.name,
    firstName: tenant?.userInfo?.firstName,
    lastName: tenant?.userInfo?.lastName,
    email: tenant?.userInfo?.email,
    tenantEmail: tenant?.email,
    phoneNo: formatPhoneNumberWithoutMask(tenant?.phoneNo)?.slice(-10),
    subDomain: tenant?.subDomain,
    isActive: tenant?.isActive,
    addressLine1: tenant?.location?.addressLine1,
    addressLine2: tenant?.location?.addressLine2,
    addressLine3: tenant?.location?.addressLine3,
    city: tenant?.location?.city,
    country: tenant?.location?.country,
    zipCode: tenant?.location?.zipCode,
    state: tenant?.location?.state
  };
  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={`${ROUTES?.TENANT}`} />
          <span className="portal-header">{tenant?.name}</span>
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <AddTenantForm
            tenantData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
            isEdit
          />
        )}
      </CardWrapper>
    </Card>
  );
};

export default withRouter(TenantEdit);
