import { Col, Divider, Row } from 'antd';
import moment from 'moment';
import React from 'react';

const ApiLogDetails = ({ logData }) => {
  return (
    <div className="api-log-details">
      <Row gutter={20}>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Method :</span>
            <span className="field-value">{logData?.method || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Tenant :</span>
            <span className="field-value">{logData?.tenant || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
          <div className="field-detail">
            <span className="field-label">IP Address :</span>
            <span className="field-value">
              {logData?.publicIpAddress || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={24} lg={12} xl={8} xxl={8}>
          <div className="field-detail">
            <span className="field-label">Token :</span>
            <span className="field-value">{logData?.token || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={24} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Created At :</span>
            <span className="field-value">
              {moment(logData?.createdAt)?.format('YYYY-MM-DD') || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={24} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Status Code :</span>
            <span className="field-value">{logData?.statusCode || '-'}</span>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <div className="field-detail">
            <span className="field-label">Request :</span>
            {logData?.method === 'GET' && (
              <pre className="params-info">
                {JSON.stringify(logData?.input?.query, undefined, 2) || '-'}
              </pre>
            )}
            {logData?.method === 'POST' && (
              <pre className="params-info">
                {JSON.stringify(logData?.input?.body?.data, undefined, 2) ||
                  '-'}
              </pre>
            )}
            {logData?.method === 'PATCH' && (
              <pre className="params-info">
                {JSON.stringify(logData?.input?.body?.data, undefined, 2) ||
                  '-'}
              </pre>
            )}
          </div>
        </Col>
      </Row>

      <Row>
        {logData?.beforeData && (
          <Col span={11}>
            <div className="field-detail">
              <span className="field-label">Before Data :</span>
              <pre className="params-info">
                {JSON.stringify(logData?.beforeData, undefined, 2) || '-'}
              </pre>
            </div>
          </Col>
        )}
        {!logData?.beforeData && logData?.afterData && (
          <Col span={11}>
            <div className="field-detail">
              <span className="field-label">Before Data :</span>
              <pre className="params-info">
                {JSON.stringify(logData?.input?.body?.data, undefined, 2) ||
                  '-'}
              </pre>
            </div>
          </Col>
        )}
        {logData?.afterData && (
          <Col span={2}>
            <Divider type="vertical" className="info-divider" />
          </Col>
        )}
        {logData?.afterData && (
          <Col span={11}>
            <div className="field-detail">
              <span className="field-label">After Data :</span>
              <pre className="params-info">
                {JSON.stringify(logData?.afterData, undefined, 2) || '-'}
              </pre>
            </div>
          </Col>
        )}
      </Row>

      {logData?.error && (
        <Row gutter={16}>
          <Col span={24}>
            <div className="field-detail">
              <span className="field-label">Error :</span>
              <span className="params-info">
                {JSON.stringify(logData?.error?.message) || '-'}
              </span>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ApiLogDetails;
