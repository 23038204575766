import { gql } from '@apollo/client';

export const CREATE_TENANT = gql`
  mutation addTenant(
    $tenantInput: TenantSpecificInput!
    $userInput: AdminPortalUserInput!
    $locationInput: TenantLocationInput!
  ) {
    createTenant(
      data: {
        userInput: $userInput
        tenantInput: $tenantInput
        locationInput: $locationInput
      }
    ) {
      message
      status
    }
  }
`;

export const EDIT_TENANT = gql`
  mutation updateTenant($data: TenantUpdateInput, $where: TenantWhereInput) {
    updateTenant(where: $where, data: $data) {
      message
      status
    }
  }
`;

export const GRANT_TENANT_ACCESS_TO_ADMIN = gql`
  mutation grantTenantAccessToAdmin(
    $data: loginInput!
    $where: TenantWhereInput!
  ) {
    grantTenantAccessToAdmin(where: $where, data: $data)
  }
`;
