import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './apiLogsModule.less';
import ApiLogList from './pages/ApiLogList';
import ApiLogView from './pages/ApiLogView';

const ApiLogWrapper = () => {
  return (
    <div className="api-log-wrapper">
      <Switch>
        <Route exact path={ROUTES?.API_LOGS} component={ApiLogList} />
        <Route path={`${ROUTES?.API_LOGS}/view/:id`} component={ApiLogView} />;
      </Switch>
    </div>
  );
};
export default ApiLogWrapper;
