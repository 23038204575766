import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import history from '../historyData';
import ModalComponent from './ModalComponent';

export default function RouterPrompt(props) {
  const {
    when = false,
    title = 'Leave this page',
    description = 'There are unsaved changes. Are you sure want to leave this page ?',
    okText = 'Confirm',
    cancelText = 'Cancel'
  } = props;

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const currentLocation = useLocation();

  const unblockRef = useRef();

  useEffect(() => {
    if (when) {
      // eslint-disable-next-line no-undef
      window.onbeforeunload = function () {
        return true;
      };
    }
    unblockRef.current = history?.block((location) => {
      if (when && location?.pathname !== currentLocation?.pathname) {
        setCurrentPath(location?.pathname);
        setShowPrompt(true);
        return false;
      }
      return true;
    });
    return () => {
      unblockRef?.current();
      // eslint-disable-next-line no-undef
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [when]);

  const handleOK = () => {
    if (unblockRef) {
      unblockRef?.current();
    }
    setShowPrompt(false);
    history?.push(currentPath);
  };

  const handleCancel = () => {
    setShowPrompt(false);
  };

  return showPrompt ? (
    <ModalComponent
      title={title}
      visible={showPrompt}
      onOk={handleOK}
      okText={okText}
      onCancel={handleCancel}
      cancelText={cancelText}
      okButtonProps={{ className: 'common-button' }}
      cancelButtonProps={{ className: 'common-button discard-button' }}
      closable
    >
      {description}
    </ModalComponent>
  ) : null;
}
