import { Input } from 'antd';
import React from 'react';

const InputComponent = (props) => {
  const { name = '' } = props;

  return (
    <Input type="text" allowClear placeholder={`Enter ${name}`} {...props} />
  );
};

export default InputComponent;
