import { Layout } from 'antd';
import React from 'react';
import HotKeysModal from '../components/Hotkeys/HotKeysModal';
import './App.css';
import AppHeader from './components/header/AppHeader';
import Sidebar from './components/sidebar/Sidebar';
import ContentRoutes from './ContentRoutes';

const { Content } = Layout;

const App = () => {
  return (
    <>
      <Layout className="gx-app-layout">
        <Sidebar />
        <Layout>
          <AppHeader />
          <Content className="gx-layout-content">
            <div className="gx-main-content-wrapper">
              <ContentRoutes />
            </div>
          </Content>
        </Layout>
      </Layout>
      <HotKeysModal />
    </>
  );
};

export default App;
