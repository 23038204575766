import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import UserCreate from './pages/UserCreate';
import UserEdit from './pages/UserEdit';
import UserList from './pages/UserList';
import UserView from './pages/UserView';

const UserWrapper = () => {
  return (
    <div className="user-profile">
      <Switch>
        <Route exact path={ROUTES?.USERS} component={UserList} />
        <Route path={`${ROUTES?.USERS}/add`} component={UserCreate} />
        <Route path={`${ROUTES?.USERS}/edit/:id`} component={UserEdit} />
        <Route path={`${ROUTES?.USERS}/view/:id`} component={UserView} />
      </Switch>
    </div>
  );
};

export default UserWrapper;
