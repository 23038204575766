import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import { forEach } from 'lodash';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import PlanForm from '../components/PlanForm';
import CardWrapper from '../components/PlanTabs';
import { UPDATE_PLAN } from '../graphql/Mutations';
import { FETCH_PLAN } from '../graphql/Queries';

const PlanEdit = (props) => {
  const { match: { params: { id } = {} } = {} } = props;
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [initialValue, setInitialValue] = useState(null);

  const [updatePlan] = useMutation(UPDATE_PLAN, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const { data: { subscriptionPlan } = {} } = useQuery(FETCH_PLAN, {
    variables: {
      where: {
        id
      }
    },
    onCompleted: (res) => {
      const featuresArray = [];
      forEach(res?.subscriptionPlan?.features, (item) => {
        featuresArray?.push({
          feature: item
        });
      });
      const initialValueObj = {
        features: featuresArray,
        name: res?.subscriptionPlan?.name,
        price: res?.subscriptionPlan?.price,
        typeOfPlan: res?.subscriptionPlan?.typeOfPlan,
        billingCycle: res?.subscriptionPlan?.billingCycle,
        isActive: res?.subscriptionPlan?.isActive,
        description: res?.subscriptionPlan?.description
      };
      setInitialValue(initialValueObj);
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const featuresArray = [];

    forEach(formValues?.features, (item) => {
      featuresArray?.push(item?.feature);
    });
    const finalPlanObj = {
      ...formValues,
      price: Number(formValues?.price),
      features: featuresArray
    };

    try {
      const response = await updatePlan({
        variables: {
          data: finalPlanObj,
          where: {
            id
          }
        }
      });
      if (response?.data?.updateSubscriptionPlan) {
        setSubmitLoading(false);
        history?.push(ROUTES?.PLANS);
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={`${ROUTES?.PLANS}`} />
          {subscriptionPlan && (
            <span className="portal-header">{subscriptionPlan?.name}</span>
          )}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <PlanForm
            isEdit
            planData={initialValue}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
          />
        )}
      </CardWrapper>
    </Card>
  );
};
export default withRouter(PlanEdit);
