import { Col, Divider, Row } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { formatPhoneNumber } from '../../../common/utils';

const rolesObj = {
  ADMIN: 'Admin',
  SUPER_ADMIN: 'Super Admin'
};

const UserDetails = ({ userData }) => {
  return (
    <div className="user-details">
      <span className="form-divider-text">MANDATORY</span>
      <Divider className="form-divider" />
      <Row gutter={20}>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">First Name :</span>
            <span className="field-value">{userData?.firstName || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Last Name :</span>
            <span className="field-value">{userData?.lastName || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Contact :</span>
            <span className="field-value">
              {formatPhoneNumber(userData?.phoneNo) || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Email :</span>
            <span className="field-value">{userData?.email || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Roles:</span>
            <span className="field-value">
              {map(userData?.roles, (role) => {
                return <span>{rolesObj[role]}</span>;
              })?.reduce((prev, curr) => {
                if (prev === '') {
                  return curr;
                }
                return [prev, ', ', curr];
              }, '')}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Status *</span>
            <span className="field-value">
              {userData?.isActive ? 'Active' : 'InActive'}
            </span>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <span className="form-divider-text optional-divider">OPTIONAL</span>
        <Divider className="form-divider optional-divider" />
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">AddressLine 1 :</span>
            <span className="field-value">
              {userData?.locations[0]?.addressLine1 || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Country :</span>
            <span className="field-value">
              {userData?.locations[0]?.country || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">State :</span>
            <span className="field-value">
              {userData?.locations[0]?.state || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">City :</span>
            <span className="field-value">
              {userData?.locations[0]?.city || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Postal Code :</span>
            <span className="field-value">
              {userData?.locations[0]?.zipCode || '-'}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default UserDetails;
