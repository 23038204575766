import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

const GoBackButton = ({ customLink = '' }) => {
  const history = useHistory();
  if (customLink) {
    return (
      <Button className="b-0" onClick={() => history?.push(customLink)}>
        <LeftOutlined />
      </Button>
    );
  }
  return (
    <Button className="b-0" onClick={() => history?.goBack()}>
      <LeftOutlined />
    </Button>
  );
};

export default GoBackButton;
