import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formatPhoneNumberWithoutMask } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import AddTenantForm from '../components/AddTenantForm';
import CardWrapper from '../components/TenantTabs';
import { CREATE_TENANT } from '../graphql/Mutations';

const AddEditTenant = () => {
  const { id } = useParams();
  const [submitLoading, setSubmitLoading] = useState(false);

  const [addTenant] = useMutation(CREATE_TENANT, {
    onCompleted(res) {
      setSubmitLoading(false);
      history?.push(
        `${ROUTES?.TENANT}/view/${res?.createTenant?.data?.id}${ROUTES?.BRANDS}`
      );
    },
    onError() {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const {
      firstName,
      lastName,
      email: mail,
      phoneNo,
      name,
      subDomain: domainName,
      description,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      county,
      country,
      state,
      zipCode,
      isActive,
      tenantEmail
    } = formValues;

    const tenantInput = {
      name,
      subDomain: domainName,
      description,
      email: tenantEmail,
      phoneNo: formatPhoneNumberWithoutMask(phoneNo)?.slice(-10),
      isActive,
      createFrom: 'ADMIN_PORTAL'
    };
    const locationInput = {
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      county,
      country,
      state,
      zipCode
    };
    const userInput = {
      firstName,
      lastName,
      email: mail
    };

    try {
      const response = await addTenant({
        variables: {
          userInput,
          tenantInput,
          locationInput
        }
      });
      if (response?.data?.createTenant) {
        history?.push(`${ROUTES?.TENANT}`);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    country: 'USA'
  };

  return (
    <Card className="full-height-card card-body-p-20">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton />
          <span className="portal-header">
            {id ? 'Edit Tenant' : 'Create Tenant'}
          </span>
        </div>
      </Portal>
      <CardWrapper>
        <AddTenantForm
          tenantData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      </CardWrapper>
    </Card>
  );
};
export default AddEditTenant;
