import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Col, Form, Row, Space } from 'antd';
import React, { useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { REGEX } from '../../../common/constants';
import {
  formatPhoneNumberWithoutMask,
  formValidatorRules
} from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import ChangePasswordModal from '../components/ChangePasswordModal';
import { UPDATE_PROFILE } from '../graphql/Mutations';
import { GET_PROFILE } from '../graphql/Queries';
import '../userProfile.less';

const responsive = {
  xd: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 12
};

const UserProfile = () => {
  const { required, email, requiredWhiteSpaceAllowed } = formValidatorRules;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form] = Form?.useForm();

  const { data, refetch } = useQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setLoading(false);
    },
    onError: () => {}
  });

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    onError: () => {}
  });

  const onFinish = async (values) => {
    setLoading(true);
    const submitValues = {
      ...values
    };
    await updateProfile({
      variables: {
        data: submitValues
      }
    });
    await refetch();
    setLoading(false);
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <Card className="full-height-card card-body-p-20 user-profile-wrapper">
      <Portal portalId="header-left-content">
        <span className="portal-header">Edit Profile</span>
      </Portal>
      <Portal portalId="header-right-content">
        <Space>
          <Button onClick={() => setPasswordVisible(true)}>
            Change Password
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Space>
      </Portal>
      <div className="user-profile">
        <Form
          labelCol={{
            span: 24
          }}
          wrapperCol={{
            span: 24
          }}
          name="edit-profile"
          className="edit-profile"
          id="edit-profile"
          form={form}
          initialValues={{
            ...data?.getProfile,
            phoneNo: formatPhoneNumberWithoutMask(
              data?.getProfile?.phoneNo
            )?.slice(-10)
          }}
          onFinish={onFinish}
        >
          {passwordVisible && (
            <ChangePasswordModal
              visible={passwordVisible}
              setVisible={setPasswordVisible}
            />
          )}
          <Row gutter={20}>
            <Col {...responsive}>
              <Form.Item rules={[required]} name="firstName" label="First Name">
                <InputComponent />
              </Form.Item>
              <Form.Item rules={[required, email]} name="email" label="Email">
                <InputComponent />
              </Form.Item>
              <Form.Item
                rules={[requiredWhiteSpaceAllowed]}
                name="roles"
                label="Roles"
              >
                <SelectComponent mode="multiple" disabled />
              </Form.Item>
            </Col>
            <Col {...responsive}>
              <Form.Item rules={[required]} name="lastName" label="Last name">
                <InputComponent />
              </Form.Item>
              <Form.Item
                label="Contact No"
                name="phoneNo"
                rules={[
                  required,
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        // eslint-disable-next-line no-param-reassign
                        value = value?.split(' ')?.join('');
                        const numberPattern = REGEX?.PHONE;
                        if (!numberPattern?.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'should be a valid phone number'
                          );
                        }
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <ReactInputMask
                  mask="(999) 999-9999"
                  placeholder="(___) ___-____"
                  className="col"
                  allowClear
                >
                  {(inputProps) => (
                    <InputComponent id="import-phone-input" {...inputProps} />
                  )}
                </ReactInputMask>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  );
};

export default UserProfile;
