import { useLazyQuery } from '@apollo/client';
import { Button, Checkbox, Divider, Empty, Tag } from 'antd';
import { debounce, filter, find, forEach, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import FilterSelectedIcon from '../../../assets/filter-selected.svg';
import FilterIcon from '../../../assets/filter.svg';
import { SKIP_RECORD } from '../../../common/constants';
import { formatPhoneNumber } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import SearchComponent from '../../../components/SearchComponent';
import ShowMoreComponent from '../../../components/ShowMoreComponent';
import TableComponent from '../../../components/TableComponent';
import { ROLE_FILTER, USER_FILTER } from '../../users/graphql/Queries';
import { FETCH_TENANT_USERS } from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1
};

const UserTable = ({ tenantId }) => {
  const {
    state: { pageSize, filterData }
  } = useContext(AppContext);

  let scrollDebounce = null;

  const initialUserFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
    showCurrentUser: true,
    tenantId
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [userFilter, setUserFilter] = useState(initialUserFilter);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [filtersCopyState, setFiltersCopyState] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});

  const [userFilters] = useLazyQuery(USER_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.userFilters?.data, (item) => {
          if (filterIndex === 'name') {
            optionsCopy?.push(`${item?.firstName} ${item?.lastName}`);
          } else {
            optionsCopy?.push(item?.[filterIndex]);
          }
        });
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.userFilters?.data, (item) => {
          if (filterIndex === 'name') {
            optionsCopy?.push(`${item?.firstName} ${item?.lastName}`);
          } else {
            optionsCopy?.push(item?.[filterIndex]);
          }
        });
        setFilterList(optionsCopy);
      }
      if (res?.userFilters?.data?.length < SKIP_RECORD) {
        setFilterIsEnd(true);
      }
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [roleFilter] = useLazyQuery(ROLE_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.roleFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key })
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.roleFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key })
        );
        setFilterList(optionsCopy);
      }
      if (res?.roleFilters?.data?.length < SKIP_RECORD) {
        setFilterIsEnd(true);
      }
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [fetchTenantUserData, { loading, data }] = useLazyQuery(
    FETCH_TENANT_USERS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.users?.count
        };
        setPaginationProp(pagination);
        setFetchLoading(false);
      },
      onError: () => {}
    }
  );

  useEffect(() => {
    fetchTenantUserData({
      variables: {
        filter: userFilter,
        ...(filterData && { where: filterData })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    fetchTenantUserData({
      variables: {
        filter: { ...userFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setUserFilter({
      ...userFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'roles':
          roleFilter({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: ['label', 'key']
              }
            }
          });
          break;

        default:
          userFilters({
            variables: {
              filter: {
                sortOn: filterIndex === 'name' ? 'firstName' : filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField:
                  filterIndex === 'name'
                    ? ['firstName', 'lastName']
                    : filterIndex,
                showCurrentUser: true,
                tenantId
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);

        switch (dataIndex) {
          case 'roles':
            roleFilter({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: ['label', 'key']
                }
              }
            });
            break;

          default:
            userFilters({
              variables: {
                filter: {
                  sortOn: filterIndex === 'name' ? 'firstName' : filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField:
                    filterIndex === 'name'
                      ? ['firstName', 'lastName']
                      : filterIndex,
                  tenantId
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleResetRole = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    setFilters(filtersCopy);
    fetchTenantUserData({
      variables: {
        filter: { ...userFilter, skip: 0, sortOn: 'createdAt', sortBy: 'DESC' },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    setUserFilter({
      ...userFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'roles':
        roleFilter({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: ['label', 'key']
            }
          }
        });
        break;

      default:
        userFilters({
          variables: {
            filter: {
              sortOn: filterIndex === 'name' ? 'firstName' : filterIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              showCurrentUser: true,
              getDBField:
                filterIndex === 'name'
                  ? ['firstName', 'lastName']
                  : filterIndex,
              tenantId
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(filtersCopyState[dataIndex], (item) => item !== value)
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex, isContactNo = false) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'isActive' && (
            <SearchComponent
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => {
                return (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <>
                        {dataIndex === 'roles' ? (
                          <>
                            <span
                              title={
                                find(filterList, ['key', item])?.label ||
                                item?.toString()
                              }
                            >
                              {find(filterList, ['key', item])?.label ||
                                item?.toString()}
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              title={
                                isContactNo
                                  ? formatPhoneNumber(
                                      item?.label || item?.toString()
                                    )
                                  : item?.label || item?.toString()
                              }
                            >
                              {isContactNo
                                ? formatPhoneNumber(
                                    item?.label || item?.toString()
                                  )
                                : item?.label || item?.toString()}
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </Tag>
                );
              })}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => {
                return (
                  <div className="single-checkbox-div" key={item?.key || item}>
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      {dataIndex === 'isActive' ? (
                        <span title={item === true ? 'Active' : 'Inactive'}>
                          {item === true ? 'Active' : 'Inactive'}
                        </span>
                      ) : (
                        <span
                          title={
                            isContactNo
                              ? formatPhoneNumber(
                                  item?.label || item?.toString()
                                )
                              : item?.label || item?.toString()
                          }
                        >
                          {isContactNo
                            ? formatPhoneNumber(item?.label || item?.toString())
                            : item?.label || item?.toString()}
                        </span>
                      )}
                    </Checkbox>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="reset-button"
            id="roles-filter-reset"
            onClick={() => handleResetRole(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button ok-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters && filters[dataIndex]?.length > 0 ? (
        <img src={FilterSelectedIcon} alt="filter-icon" width={16} />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownVisibleChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const columns = [
    {
      title: 'NAME',
      ellipsis: true,
      width: 130,
      dataIndex: 'firstName',
      key: 'firstName',
      className: 'max-width-column',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
      render: (text, record) => {
        return `${record?.firstName} ${record?.lastName}`;
      }
    },
    {
      title: 'EMAIL',
      ellipsis: true,
      width: 180,
      dataIndex: 'email',
      className: 'max-width-column',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      key: 'email',
      render: (email) => <span title={email}>{email || '-'} </span>
    },
    {
      title: 'CONTACT',
      width: 180,
      ellipsis: true,
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order,
      render: (phone) => {
        return formatPhoneNumber(phone) || '-';
      }
    },
    {
      title: 'ROLES',
      width: 250,
      ellipsis: true,
      dataIndex: 'roles',
      key: 'roles',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'roles' && sortedInfo?.order,
      ...filterPopup('roles'),
      render: (roles, record) => (
        <ShowMoreComponent show={1} data={record?.roleValues} />
      )
    },
    {
      title: 'STATUS',
      width: 120,
      dataIndex: 'isActive',
      key: 'isActive',
      ...filterPopup('isActive'),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    }
  ];

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pageSize;
    setSortedInfo(sorter);
    setUserFilter({ ...userFilter, skip });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchTenantUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'updatedAt',
        sortBy: 'DESC'
      });
      fetchTenantUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'updatedAt',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };
  const onSearchChange = async (value) => {
    fetchTenantUserData({
      variables: {
        filter: {
          ...userFilter,
          skip: value ? 0 : userFilter?.limit * (paginationProp?.current - 1),
          search: value
        }
      }
    });
    setFetchLoading(true);
  };

  return (
    <div>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            id="search-container-id"
            placeholder="User name or other detail.."
            name="Users"
            getData={onSearchChange}
          />
        </div>
      </div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            loadingData={loading || fetchLoading}
            columns={columns}
            data={data?.users?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </div>
  );
};
export default UserTable;
