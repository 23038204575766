import moment from 'moment';
import { DEFAULT_DATE_FORMAT, REGEX } from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) => {
  // eslint-disable-next-line no-undef
  return document.getElementById(portalId);
};

export const isPortalIdExists = (portalId) => {
  return !!injectUsingPortal(portalId);
};

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) => {
  // eslint-disable-next-line no-undef
  return document.getElementById(portalId);
};

export const isDocumentIdExist = (portalId) => {
  return !!getElementFromDocumentId(portalId);
};
// Check for document Id's exists end

export const refreshGrid = () => {
  // eslint-disable-next-line no-undef
  window?.dispatchEvent(new Event('refresh-grid'));
};

export const formatDate = (
  datetime,
  format = `${DEFAULT_DATE_FORMAT} hh:mm A`
) => {
  if (datetime && moment && format) {
    return moment(datetime)?.format(format);
  }

  return datetime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
    whitespace: true
  },
  requiredWhiteSpaceAllowed: {
    required: true,
    message: 'Required'
  },
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid Email!');
      }
      return Promise?.resolve();
    }
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    }
  }),
  subDomain: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.SUB_DOMAIN?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please Enter a valid domain name!');
      }
      return Promise?.resolve();
    }
  }),
  zipCode: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (value?.length < 3 || value?.length > 5 || parseInt(value, 10) === 0) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid ZIP Code');
      }
      return Promise?.resolve();
    }
  })
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj.setUTCHours(timeObj.getUTCHours());
  formattedDateTime = new Date(formattedDateTime).setUTCMinutes(
    timeObj.getUTCMinutes()
  );
  formattedDateTime = new Date(formattedDateTime).toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD'
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

export const handleCsvDownload = (data, name = 'data.csv') => {
  // eslint-disable-next-line no-undef
  const a = document?.createElement('a');
  a.href = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(data)}`;
  a.target = '_Blank';
  a.download = name;
  // eslint-disable-next-line no-undef
  document?.body?.appendChild(a);
  a?.click();
  // eslint-disable-next-line no-undef
  document?.body?.removeChild(a);
};

export const downloadFromURL = (uri, name) => {
  // eslint-disable-next-line no-undef
  const link = document?.createElement('a');
  link.download = name;
  link.href = uri;
  // eslint-disable-next-line no-undef
  document?.body?.appendChild(link);
  link?.click();
  // eslint-disable-next-line no-undef
  document?.body?.removeChild(link);
};
