import { Button, Card } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import TenantTable from '../components/TenantTable';

const TenantList = () => {
  const history = useHistory();
  const handleAdd = () => {
    history?.push(`${ROUTES?.TENANT}/add`);
  };
  return (
    <Card className="full-height-card card-body-p-20">
      <Portal portalId="header-left-content">
        <span className="portal-header">Tenants</span>
      </Portal>
      <Portal portalId="header-right-content">
        <Button type="primary" onClick={handleAdd}>
          Add Tenant
        </Button>
      </Portal>
      <TenantTable />
    </Card>
  );
};
export default TenantList;
