import { Card } from 'antd';
import React from 'react';
import Portal from '../../../components/Portal';
import PlansTable from '../components/PlansTable';

const PlansList = () => {
  return (
    <Card className="full-height-card card-body-p-20">
      <Portal portalId="header-left-content">
        <span className="portal-header">Plans</span>
      </Portal>
      <PlansTable />
    </Card>
  );
};
export default PlansList;
