import { MoreOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Popconfirm, Popover } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import { UPDATE_PLAN } from '../graphql/Mutations';
import { FETCH_PLANS } from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1
};

const PlansTable = () => {
  const {
    state: { pageSize }
  } = useContext(AppContext);

  const initialPlanFilter = {
    skip: 0,
    limit: 10,
    sortOn: 'createdAt',
    sortBy: 'DESC'
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [updatePlan] = useMutation(UPDATE_PLAN, {
    onError: () => {}
  });

  const [subscriptionPlans, { data, loading }] = useLazyQuery(FETCH_PLANS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.tenantSubscriptionPlans?.count
      };
      setPaginationProp(pagination);
    }
  });

  const handleUserStatus = async (editPlanData) => {
    const editData = {
      isActive: !editPlanData?.isActive,
      features: editPlanData?.features,
      name: editPlanData?.name,
      price: editPlanData?.price,
      typeOfPlan: editPlanData?.typeOfPlan,
      billingCycle: editPlanData?.billingCycle,
      description: editPlanData?.description
    };

    try {
      const response = await updatePlan({
        variables: {
          data: editData,
          where: {
            id: editPlanData?.id
          }
        }
      });
      if (response?.data?.updateSubscriptionPlan) {
        subscriptionPlans({
          variables: {
            filter: initialPlanFilter
          }
        });
      }
    } catch (error) {
      return error;
    }
  };
  const handleAddEditUser = (editPlanData) => {
    if (editPlanData?.id) {
      // dispatch({ type: 'SET_FILTER_DATA', data: filters });
      history?.push(`${ROUTES?.PLANS}/edit/${editPlanData?.id}`);
    } else {
      history?.push(`${ROUTES?.PLANS}/add`);
    }
  };

  const renderActionButtons = (editPlanData) => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="user-table-edit-btn"
          className="b-0"
          onClick={() => {
            handleAddEditUser(editPlanData);
          }}
        >
          Edit
        </Button>
        <Popconfirm
          title={`Are you sure to ${
            editPlanData?.isActive ? 'Mark Inactive' : 'Mark Active'
          }?`}
          onConfirm={() => handleUserStatus(editPlanData)}
          okText="Yes"
          cancelText="No"
        >
          <Button id="user-table-status-btn" className="b-0">
            {editPlanData?.isActive ? 'Mark Inactive' : 'Mark Active'}
          </Button>
        </Popconfirm>
      </div>
    );
  };

  useEffect(() => {
    subscriptionPlans({
      variables: {
        filter: initialPlanFilter
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      className: 'max-width-column',
      width: 200,
      render: (name) => {
        return <span>{name}</span>;
      }
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      render: (description) => {
        return <span>{description}</span>;
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      ellipsis: true,
      width: 120,
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    },
    {
      dataIndex: 'id',
      align: 'right',
      width: 50,
      fixed: 'right',
      render: (id, record) => {
        return (
          <Popover
            placement="bottom"
            overlayClassName="action-button"
            content={renderActionButtons(record)}
          >
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  return (
    <div className="common-table">
      {pageSize && (
        <TableComponent
          loadingData={loading}
          columns={columns}
          data={data?.subscriptionPlans?.data || []}
          // onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(obj) => obj?.id}
          // rowSelection={rowSelection}
        />
      )}
    </div>
  );
};
export default PlansTable;
