import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Popover } from 'antd';
import { find, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import CommonGoogleFileUpload from '../../../components/CommonGoogleFileUpload';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import SelectComponent from '../../../components/SelectComponent';
import { IMPORT_TYPES } from '../graphql/Queries';

const ImportModal = ({
  showImportModal = false,
  setShowImportModal,
  fromImport = false,
  module = 'user',
  folder = 'USER',
  callback
}) => {
  const [importTypes, { data, loading }] = useLazyQuery(IMPORT_TYPES, {
    fetchPolicy: 'network-only',
    onError: () => {}
  });
  const [value, setValue] = useState(null);
  const { Option } = SelectComponent;
  let selectedObject = {};

  if (value) {
    selectedObject = find(data?.importTypes, (item) => item?.key === value);
  }

  useEffect(() => {
    if (fromImport) {
      importTypes({
        variables: {
          filter: {
            limit: 20,
            skip: 0
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <div>
      <ul>
        <li>The list should be comma-separated</li>
        <li>
          Fields with embedded commas or double-quote characters must be quoted.
        </li>
        <li>
          Each of the embedded double-quote characters must be represented by a
          pair of double-quote characters.
        </li>
      </ul>
    </div>
  );

  const renderTitle = (
    <div className="d-flex justify-between align-center import-title">
      <span>IMPORT</span>
      <Popover
        content={content}
        placement="top"
        title="Import Rules"
        trigger="hover"
        overlayClassName="info-tooltip"
      >
        <InfoCircleOutlined className="info-icon" />
      </Popover>
    </div>
  );

  return (
    <ModalComponent
      title={renderTitle}
      visible={showImportModal}
      footer={null}
      onCancel={() => setShowImportModal(false)}
      destroyOnClose
      wrapClassName="import-common-modal"
    >
      {loading ? (
        <LoaderComponent setHeight={10} />
      ) : (
        <div>
          {fromImport && (
            <>
              <span className="module-text">Select Module</span>
              <SelectComponent
                className="fill-width module-select"
                id="search-container-id"
                value={value}
                onChange={(selected) => setValue(selected)}
              >
                {map(data?.importTypes, (item) => (
                  <Option key={item?.key} value={item?.key}>
                    {item?.label}
                  </Option>
                ))}
              </SelectComponent>
            </>
          )}
          {(fromImport && value) ||
            (!fromImport && (
              <CommonGoogleFileUpload
                folder={fromImport ? value : folder}
                url={
                  fromImport
                    ? selectedObject?.sampleFile?.url
                    : `https://storage.googleapis.com/drf-server-dev/assets/imports/${module}-sample.csv`
                }
                fromImport={fromImport}
                setShowImportModal={setShowImportModal}
                callback={callback}
              />
            ))}
        </div>
      )}
    </ModalComponent>
  );
};

export default ImportModal;
