import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import RoleList from './pages/RoleList';

const RoleWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.USERS_ROLES} component={RoleList} />
      <Route path={`${ROUTES?.USERS_ROLES}/create`} component={RoleList} />
      <Route path={`${ROUTES?.USERS_ROLES}/:id/edit`} component={RoleList} />
    </Switch>
  );
};

export default RoleWrapper;
