import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import PermissionList from './pages/PermissionList';

const PermissionWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.USERS_PERMISSION} component={PermissionList} />
      <Route
        path={`${ROUTES?.USERS_PERMISSION}/create`}
        component={PermissionList}
      />
      <Route
        path={`${ROUTES?.USERS_PERMISSION}/:id/edit`}
        component={PermissionList}
      />
    </Switch>
  );
};

export default PermissionWrapper;
